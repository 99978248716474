import { useTranslation } from 'react-i18next';
import { useState, useContext, useImperativeHandle, useEffect, useRef, forwardRef } from 'react';
import '../../../i18n'
import { Location } from '../../Checkout/Location/Location';
import { Company } from '../../Checkout/Company/Company';
import { PaymentFormContext, updateDeliveryAddress, updateDeliveryCompany } from '../PaymentFormReducer';
import { BillingDetails } from './BillingDetails';
import { AddressFields } from './AddressFields';
import Button from '../../UI/Buttons/Button';
import { formatDiagnosticsWithColorAndContext } from 'typescript';
import { APIGet, APIPost, getEnvironment } from '../../API/APIRequest';
import { checkIsBlank } from '../../Utilities/FieldValidations';
import { use } from 'i18next';
import { Loading } from '../../Utilities/Loading';
import { RecipientDeliveryDetails } from './RecipientDeliveryDetails';
import {  gtmEventTriggers } from '../../Utilities/GtmFunctions';
import { useAuth } from '../../Authentication/AuthenticationProvider';
import { GenericErrorBox } from '../../UI/ErrorBox/GenericErrorBox';



export const DeliveryDetails = forwardRef((props, ref) => {
  const { flags, setSectionComplete, setAlreadyPurchased, fid, optionalEmail, setOptionalEmail, setPurchaseRestriction, checkForBasketRestrictions, variantIdCsv, deliveryCountryCode, companyProperties, setCompanyProperties, getAddressStructure, deliveryAddressStructure, showCompany, loqateKey, setBillingLoadingState, guestSession, setGiftRecipientDetails, recipientDetails, setRecipientDetails, setLoading, selectedAddress, selectedCompany, setSelectedAddress, setSelectedCompany, IsFilled, setIsFilled, setDeliveryAddressStructure, setDeliveryCountryCode, sectionComplete,updateAndRefreshBasket } = props;
  const { t, i18n } = useTranslation();
  const { basket, loading } = props
  const [countryCode, setCountryCode] = useState(deliveryCountryCode)
  const [IsInvalid, setIsInvalid] = useState({ userEmail: false });
  const [showContinueButton, setShowContinueButton] = useState(false)
  const [countryList, setCountryList] = useState(basket.countries || JSON.parse(sessionStorage.getItem("deliveryCountryList")));
  const [errors, setErrors] = useState([]);
  const recipientRef=useRef();
  const [editMode, setEditMode] = useState(false);
  const [initialFilledState, setInitialFilledState]=useState({});
  const [environment, setEnvironment] = useState(getEnvironment());
  const [addressStructure, setAddressStructure] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fieldRef = useRef();
  const countryRef = useRef(null);
  const deliveryErrorFocusRef=useRef(null);
  const params = new URLSearchParams(window.location.search);
  const purchasingFor= params.get("purchasingFor");
  const auth = useAuth();

  useEffect(() => {
    if (countryCode && countryCode !== deliveryCountryCode) {
      setDeliveryCountryCode(countryCode) 
      setSelectedAddress(selectedAddress => ({ COUNTRY: selectedAddress.COUNTRY })) ;
      setSelectedCompany({}); //blanking out data obj as country changes
      getAddressStructure(countryCode)
      updateAndRefreshBasket(countryCode,"destinationcountrycode");
    }
  }, [countryCode])

  useEffect(()=>{
   if(countryList.length===0){
    setCountryList(basket.countries);
  }
  sessionStorage.setItem("deliveryCountryList", JSON.stringify(basket.countries)) 
  }, [fid, basket.id])
 
 useEffect(()=>{
    setAddressStructure(deliveryAddressStructure);
    if (flags.isdeliverypostaladdressrequired) {
        addressFieldsMap(deliveryAddressStructure)
    }
  }, [deliveryAddressStructure])


  const addressFieldsMap = (addressfields) => {
    addressfields.map((field, index) => {
      if (field.fieldName !== "COUNTRY") {
        //so that on state change from manual to lookup we can retain the original isFilled state
        setInitialFilledState(initialFilledState => ({...initialFilledState, [field.fieldName]: { ...initialFilledState[field.fieldName], filled: true, mandatory: field.mandatory } }));
        }
    })
  }

 function scrollToOffset(offset) {
    const duration = 200; // Animation duration in milliseconds
    const startY = window.pageYOffset || document.documentElement.scrollTop;
    const targetY = offset;
    const distance = targetY - startY;
    const startTime = performance.now();
    
    function step(currentTime) {
      const elapsed = currentTime - startTime;
      const nextPosition = startY + distance * (elapsed / duration);
      window.scrollTo(0, nextPosition);
      
      if (elapsed < duration) {
        window.requestAnimationFrame(step);
      }
    }
    
    window.requestAnimationFrame(step);
  }

  //for checking the variant type in order to show email field for recipient or not
  const checkProductTypeForGiftUser = (e) => {
    
   let typeArray = basket?.producttypes;
  
    typeArray.find((item, i)=>{
       if(item!=="Print"){
        setOptionalEmail(false);
       }
     })
  }

 //on-mount
 useEffect(()=>{

  checkProductTypeForGiftUser();

 },[])

 const SaveGiftRecipientDetails = (e) => {
    setErrors("")
   
    e.preventDefault();

    let userDataString = sessionStorage.getItem("adtoken");
    let userData = JSON.parse(userDataString);
    let validation=validateDeliveryDetails(e);
    let validationStatus=validation.status;
    let reciDetails=validation.details;
    setRecipientDetails(reciDetails);
   
    if(validationStatus){

      let postData= {
        fid: basket?.id,
        email: reciDetails?.userEmail || null,
        firstName: reciDetails?.firstName,
        surname: reciDetails?.lastName,
        addressLine1 : selectedAddress?.ADDRESS1 || null,
        postcode: selectedAddress?.POSTCODE || null,
        country:selectedAddress?.COUNTRY || null
       }

      APIPost({
      "controller": "auth",
      "action": "creategiftuser",
      "environment": environment,
      "headers": [{
        "key": "UserToken",
        "value": auth?.userData?.token || userData?.token}], 

       data: postData

    }).then((response) => {
      
    let user = response?.data?.user;
     
      if(user){
      let giftUser = {
        "name": user?.userfullname,
        "token": user?.userToken,
        "username": user?.username,
        "partyid": user?.partyid
      };

      checkForBasketRestrictions(user?.partyid, user?.username, basket)
      setGiftRecipientDetails(giftUser);
   
      if(basket.allowgifting){
        setSectionComplete(sectionComplete => ({ ...sectionComplete, deliveryDetails: true}))
        scrollToOffset(340);
        saveDeliveryDetails(reciDetails); 
          
      }}

    }).catch((error) => {
     
      var unexpected = [];
      unexpected.push("error.unexpected");
      setErrors(unexpected);
      setIsLoading(false); 
      
    });
}
}

const saveDeliveryDetails=(reciDetails)=>{

      //storing delivery details
      let deliveryDetailsToShow=  {
        "recipient": ((purchasingFor==="gift" && basket.allowgifting)  || (!purchasingFor && basket.allowgifting)) ? {
        "userEmail":  reciDetails?.userEmail ,
        "firstName":  reciDetails?.firstName ,
        "lastName":  reciDetails?.lastName} : {}, 
        "company": selectedCompany,
        "address": selectedAddress
        
      }
    
      if(deliveryDetailsToShow?.address?.COUNTRY){
        sessionStorage.setItem("deliveryDetails", JSON.stringify(deliveryDetailsToShow));
      }
}

const focusOnFirstError = () => {
  setTimeout(() => {
      let firstElement = deliveryErrorFocusRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
      firstElement?.focus();
  }, 200)

}

  const validateDeliveryDetails = (e) => {
    e.preventDefault();
    setIsLoading(true)   // set local loading state to be true
    setBillingLoadingState(true); 
   
    let detailsFilled = true;
    let recipientDetailsFilled=true;
    let recipient;
    
    let newIsFilled = { ...IsFilled };

    if (flags.isdeliverypostaladdressrequired) {
        if (selectedAddress.ADDRESS1 === undefined) {
            newIsFilled = { ...newIsFilled, ADDRESS: { filled: false, mandatory: true } };
            detailsFilled = false
        }
    }  

    if (!selectedAddress.COUNTRY) {
        newIsFilled = { ...newIsFilled, COUNTRY: { filled: false, mandatory: true } }
        detailsFilled = false
    }

   if(( purchasingFor==="gift" && basket.allowgifting)  || (!purchasingFor && basket.allowgifting)){

       recipient=recipientRef?.current?.ValidationOnSubmit()
       detailsFilled=recipient?.status;

   }

    if(flags.isdeliverypostaladdressrequired){
    for (let key in IsFilled) {
   
      if (key !== "ADDRESS" && key !== "RECIPIENT" ) {
          if ((!selectedAddress[key] || selectedAddress[key] === '') && IsFilled[key]?.mandatory) {
              newIsFilled = { ...newIsFilled, [key]: { filled: false, mandatory: true } }
              detailsFilled = false
          }
      }
  }}
   

    setIsFilled({ ...newIsFilled })
    //for non-gift the validation will be completed here
    setTimeout(()=>{
      if(!basket.allowgifting){
        setSectionComplete(sectionComplete => ({ ...sectionComplete, deliveryDetails: detailsFilled }))
        saveDeliveryDetails(); 
      }
      setBillingLoadingState(false);
      setLoading(false);
    
    },[500])
    
    if(!basket.allowgifting){
    scrollToOffset(340);
    }

    if (!detailsFilled) {

      setIsLoading(false);
      focusOnFirstError();

    } else {
      
      if (flags?.isdeliverypostaladdressrequired || (purchasingFor === "gift" && basket?.allowgifting)) {
        gtmEventTriggers("add_shipping_info") //when delivery details are needed then on validation event will be triggered
       }
     
    }

  
    if(basket.allowgifting){
      return {status: detailsFilled, details:recipient?.details}
    }
    else{
      return detailsFilled;
  }

}

 //edit delivery details
  const handleOnEdit = () => {
    setErrors("")
    setEditMode(true);
    setIsLoading(false);
    sessionStorage.removeItem("deliveryDetails");
    setSectionComplete({ ...setSectionComplete, deliveryDetails: false });
    setAlreadyPurchased({status:"", text:""});
    setPurchaseRestriction({status:"", text:""});
    
    
  }

  const deliveryAddressUI = () => {
    let { COUNTRY, SOURCE, ...address } = selectedAddress
    let addressArray = Object.entries(address).filter(([key, value]) => value !== '' && key !== "COMPANY").map(([key, value]) => value);
    let deliveryAddressUI = flags.isdeliverypostaladdressrequired ? <> {selectedCompany?.COMPANY && selectedCompany?.COMPANY + ","} {Object.values(addressArray).join(', ')}, {selectedAddress?.COUNTRY}</> : selectedAddress?.COUNTRY
    return deliveryAddressUI;
  }


  const recipientDetailsUI=()=>{
    let recipientDetailsUI = <><p>{recipientDetails.firstName} {recipientDetails.lastName}</p>
    <p>{recipientDetails.userEmail}</p>
    </>
    return recipientDetailsUI;
  }


  useEffect(() => {
    if (selectedAddress?.COUNTRY) {
    
      setIsFilled({ ...IsFilled, COUNTRY: { ...IsFilled.COUNTRY, filled: true, mandatory: true } })
    }
  }, [selectedAddress.COUNTRY])

  //add gift user state too 

  return (
  
    <>{(sectionComplete?.deliveryDetails && flags.isdeliverypostaladdressrequired) || (basket.allowgifting && sectionComplete?.deliveryDetails)  ?
      <div className="delivery-address-block">
        <button onClick={() => { handleOnEdit() }}>Edit</button>

        {(purchasingFor==="gift" && basket.allowgifting )  || (!purchasingFor && basket.allowgifting )  ?  <>  
        <div className="detail-wrapper">
       
        <div className="item-wrapper">
          <p><strong>Recipient</strong></p>
          <p>{recipientDetailsUI()}</p>
          </div>
         
          <div className="item-wrapper">
          <p><strong>Address</strong></p>
          <p>{deliveryAddressUI()}</p>
          </div>
          </div>
          
        
        </> : <div className="detail-wrapper">
          <p>{deliveryAddressUI()}</p>
         </div>}
      </div>
      :
      <>
      <form  ref={deliveryErrorFocusRef} onSubmit={(purchasingFor==="gift" && basket.allowgifting)  || (!purchasingFor && basket.allowgifting) ? SaveGiftRecipientDetails : validateDeliveryDetails}>

         {/* <Loading loading={props.loading} /> */}

         {/*1st condition is for when pop is there, 2nd for gift on -> guest only */}

         {/* (!purchasingFor && guestSession  && !basket.allowcustomercheckout && basket.allowguestcheckout) */}

         <GenericErrorBox errors={errors} /> 

        { (( purchasingFor==="gift" && basket.allowgifting )  || (!purchasingFor && basket.allowgifting)) ? 
        <div className="item-container">
        <RecipientDeliveryDetails detailType="recipient" 
        ref={recipientRef}
        setRecipientDetails={setRecipientDetails}  
        recipientDetails={recipientDetails}
        IsFilled={IsFilled}  
        optionalEmail={optionalEmail}
        setIsFilled={setIsFilled}
        setIsInvalid={setIsInvalid}
        IsInvalid={IsInvalid}
         /> </div>: "" }

         {flags?.isdeliverycountryrequired ? 
          <Location countryList={countryList}
            addressType="deliveryAddress"
            setCountryCode={setCountryCode}
            countryCode={countryCode}
            IsFilled={IsFilled}
            id="delivery-country"
            name="delivery-country"
            basket={basket}
            preSelectedCountryCode={basket.countrycode}
            setIsFilled={setIsFilled}
            setSelectedAddress={setSelectedAddress}
            selectedAddress={selectedAddress} />
          : ""}

        {flags?.isdeliverypostaladdressrequired ?
          <>
                          <AddressFields companyList={props.companyList}
                              addressType="deliveryAddress"
                              countryCode={countryCode}
                              countryRef={countryRef}
                              loading={loading}
                              showCompany={showCompany}
                              initialFilledState={initialFilledState}
                              IsFilled={IsFilled}
                              setIsFilled={setIsFilled}
                              ref={fieldRef}
                              loqateKey={loqateKey}
                              setSelectedCompany={setSelectedCompany}
                              selectedCompany={selectedCompany}
                              setSelectedAddress={setSelectedAddress}
                              selectedAddress={selectedAddress}
                              addressStructure={addressStructure}
                              editMode={editMode}
                              showContinueButton={showContinueButton}
                              setShowContinueButton={setShowContinueButton}
                              setEditMode={setEditMode}
                              companyProperties={companyProperties}
                              setCompanyProperties={setCompanyProperties}
                              isGift={purchasingFor}
            />
              {showContinueButton ?
                (!isLoading ? <Button type="submit" id="deliverySubmit">{t('deliverydetails.submitbuttontext')}</Button>
                  : isLoading ? <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{t('deliverydetails.submitbuttontext')}</Button>
                    : "")
           :""}
        
          </> : ""}
          {/*Dont remove}
          {/* {guestSession==="true" && purchasingFor==="someoneelse"  ? <RecipientDeliveryDetails detailType="giftDetails" /> : ""} */}
          {/* !purchasingFor && guestSession  && !basket.allowcustomercheckout && basket.allowguestcheckout */}
          {(!flags?.isdeliverypostaladdressrequired && ((purchasingFor === "gift" && basket?.allowgifting) || (!purchasingFor && basket?.allowgifting))) ?
            (!isLoading ? <Button id="deliverySubmit" type="submit">{t('deliverydetails.submitbuttontext')}</Button>
              : isLoading ? <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{t('deliverydetails.submitbuttontext')}</Button>
                : "") : ""}
     </form>
      </>}
    </>
  );
})