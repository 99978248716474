
export { getOriginatingUrl, getThankyouPageUrl};
const discountFlow = sessionStorage.getItem("discountApplied");


const getOriginatingUrl=(basket, returnUrl)=>{
    let backurl=""
    if(!discountFlow){
        backurl = sessionStorage.getItem("originatingUrl") || basket?.originatingurl  || returnUrl ;
    }
    else{
        backurl = sessionStorage.getItem("originatingUrl") 
    }
  
    let fallbackUrl=window.location.protocol + "//" + window.location.host;
    if(!backurl){
        return fallbackUrl;
    }
    else{
        return backurl
    }
    
}

const getThankyouPageUrl=()=>{
    let orderId= sessionStorage.getItem("orderId");
    let url=""
    if(orderId){
        url=window.location.protocol + "//" + window.location.host + "/thankyou/"+ orderId
    }
    else{
        //fallbackUrl
        url=window.location.protocol + "//" + window.location.host
    }
    return url;
}