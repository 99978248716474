import { APIPost } from "../API/APIRequest";
export { loqateRequestLogs};


const loqateRequestLogs = (Id, response, type) => {

    let basketId=sessionStorage.getItem("fid");
    let brand=sessionStorage.getItem("Brand_null")
    let brandData= JSON.parse(brand);
    
    const data = new FormData();
            data.append("sessionid", Id);   //id for successful loqate request
            data.append("request", JSON.stringify(response));  //data for successful loqate request
            data.append("requesttype", type); 
            data.append("brandid", brandData.brandid); 
            data.append("basketid", basketId); 
            

         APIPost({
                "controller": "loqate",
                "action": "logloqaterequest",
                "environment":null,
                 data: data
               }).then((response)=>{
                if(response.status===200){
                    console.log("Log for loqate request created successfully") 
                }
                }).catch((error)=>{
                console.log("error in creating loqate log")
               })

}