import ReactHtmlParser from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { format } from 'react-string-format';
import usePageTitle from '../../Hooks/usePageTitle';
import useResize from '../../Hooks/useResize';
import '../../i18n';
import { APIGet, APIPost, getEnvironment } from '../API/APIRequest';
import { LoginInfo } from '../Authentication/Account/LoginInfo';
import { useAuth } from '../Authentication/AuthenticationProvider';
import { GuestUser } from '../Checkout/User/GuestUser';
import Button from '../UI/Buttons/Button';
import ErrorBox from '../UI/ErrorBox/ErrorBox';
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';
import MessageBox from '../UI/MessageBox/MessageBox';
import Modal from '../UI/Modal/Modal';
import { getOriginatingUrl, getThankyouPageUrl } from '../Utilities/GetUrlFunctions';
import {  gtmEventTriggers } from '../Utilities/GtmFunctions';
import { Loading } from '../Utilities/Loading';
import { GetADToken, RefreshADToken } from '../Utilities/TokenManager';
import { LogWebActivity } from '../Webactivity/LogWebActivity';
import "./Payment.scss";
import { AutorenewalTerms } from './Payment/AutorenewalTerms';
import { Basket, GetBasket, SaveToOrder, UpdateBasket } from './Payment/Basket';
import { BillingDetails } from './Payment/BillingDetails';
import { DeliveryDetails } from './Payment/DeliveryDetails';
import { DirectDebitForm, UpdateDirectDebitPaymentRequest } from './Payment/DirectDebitForm';
import { Invoice, UpdateInvoicePaymentRequest } from './Payment/Invoice';
import { MarketingPreferences } from './Payment/MarketingPreferences';
import { PaymentSelection } from './Payment/PaymentSelection';
import { PaypalForm, UpdatePaypalPaymentRequest } from './Payment/PaypalForm';
import { InitialiseStripe, StripeForm, UpdateStripePaymentRequest } from './Payment/StripeForm';
import { WorldPayForm } from './Payment/WorldPayForm';
import { CapturePhone } from './Payment/CapturePhone';
import { accountNumberRegex, sortCodeRegex } from '../Utilities/EmailRegex';
import DiscountCode from './Payment/PromoCode';
import PromoCode from './Payment/PromoCode';
import { loqateRequestLogs } from '../Utilities/Functions';


export function Payment(props) {

 
    const { t, i18n } = useTranslation();
    
    const deliveryRef = useRef({});
    const billingRef = useRef({});
    const [returnUrl, setReturnUrl] = useState([]);
    const [errors, setErrors] = useState([]);
    const [basketErrors, setBasketErrors] = useState([]);
    const [paymentMethodErrors, setPaymentMethodErrors] = useState();
    const [loading, setLoading] = useState(true);
    const [basket, setBasket] = useState({});
    const [vatNo, setVatNo] = useState("");
    const [billingAddress, setBillingAddress] = useState({})
    const [isLoading, setIsLoading] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState({})
    const [billingCompany, setBillingCompany] = useState({})
    const [deliveryCompany, setDeliveryCompany] = useState({})
    const [billingLoadingState, setBillingLoadingState]=useState()  //for delivery form submission animation
    const [companyList, setCompanyList] = useState([]);
    const [paymentSelection, setPaymentSelection] = useState({ paymenttype: null, updatepaymentrequestfunction: null });
    const [stripeCredentials, setStripeCredentials] = useState(null)
    const [wpHostedURL, setWpHostedURL] = useState(null)
    const [IsFilledBilling, setIsFilledBilling] = useState({});
    const [IsFilledDelivery, setIsFilledDelivery] = useState({});
    const [billingsSameCheck, setBillingsSameCheck] = useState(false)
    const [giftRecipientDetails , setGiftRecipientDetails] = useState({})
    const [vatDetails , setVatDetails] = useState({})
    const [showCompany , setShowCompany] = useState({})
    const [errorStates, setErrorStates] = useState({noPaymentSelected:false, cardHolderNameBlank: false, cardDeclined:false, autoRenewalNotTicked:false, authDD: false, isBlankAccountNameDD: false, isBlankBankDD:false, invalidAccountNumberDD:false, invalidSortcodeDD:false, invalidFormatAccountNumberDD:false, invalidFormatSortcodeDD:false, isBlankAccountNumberDD:false, isBlankSortCodeDD:false});
    const [IsAutoRenewalChecked, setIsAutoRenewalChecked] = useState(false);
    const [IsAuthDebitCardChecked, setIsAuthDebitCardChecked] = useState(false);
    const [IsMarketingPreferencesChecked, setIsMarketingPreferencesChecked] = useState(false);
    const [isPaypal, setIsPaypal] = useState(false);
    const [IsContinuousMethodPresent, setIsContinuousMethodPresent] = useState(false);
    const [showPopUp, setShowPopup] = useState(false);
    const [popUpFlow, setPopUpFlow] = useState(false);
    const paymentConfirmRef = useRef(null);
    const [sectionComplete, setSectionComplete] = useState({});
    const [recipientDetails, setRecipientDetails] = useState({firstName:"", lastName: "", userEmail: ""} );
    const [deliveryCountryCode, setDeliveryCountryCode] = useState("")
    const [billingCountryCode, setBillingCountryCode]=useState("");
    const location = useLocation()
    const [deliveryAddressStructure, setDeliveryAddressStructure] = useState([]);
    const [companyProperties, setCompanyProperties] = useState({});
    const adToken = GetADToken();
    const [guestSession, setGuestSession] = useState(location.state ? location.state.guestSession : adToken?.isGuest ? adToken :"");
    const params = new URLSearchParams(window.location.search);
    const discountRemoved= params.get("discountRemoved"); 
    const purchasingFor= params.get("purchasingFor");
    const isGuest = props.guest;
    const deliveryDetails= sessionStorage.getItem("deliveryDetails");
    const [flags, setFlags] = useState({  });
    const auth = useAuth();
    const [ locationKeys, setLocationKeys ] = useState([])
    const [oAuthentication, setOAuthentication] = useState(null);
    const [showModal, setShowModal] = useState(false)
    const [isMobile, setIsMobile] = useState(false);
    const [isDirectDebit, setIsDirectDebit] = useState(false);
    const [variantIdCsvSMO, setVariantIdCsvSMO] = useState("");
    const [variantIdCsvPR, setVariantIdCsvPR] = useState("");
    const [variantIdForPromoCode, setVariantIdForPromoCode] = useState("");
    const [loqateKey, setLoqateKey] = useState(sessionStorage.getItem("loqateKey")?sessionStorage.getItem("loqateKey"):"");
    const [showWorldPay, setShowWorldPay] = useState(false);
    const [alreadyPurchased, setAlreadyPurchased]=useState({"status":"","text":""});
    const [purchaseRestriction, setPurchaseRestriction]=useState({"status":"","text":""});
    const environment = getEnvironment();
    const [showError, setShowError] = useState(false);
    const [creditCardHolderName, setCreditCardHolderName] = useState("");
    const {setFid, metaData} = props;
    const paymentErrorFocusRef= useRef(null);
    usePageTitle(`Checkout -  ${props.brandingElement?.id}`);
    let windowSize = useResize();
    const { fid } = useParams();
    const [ddDetails, setddDetails] = useState({accountholder:"", bankname:"", accountnumber:"", sortcode:""});
    const [BankConfiguration, setBankConfiguration] = useState(sessionStorage.getItem("BankConfiguration")==="true" ? true : sessionStorage.getItem("BankConfiguration")==="false" ? false : ""); 
    const [BankValidation, setBankValidation] = useState([]);
    const [BankVerificationAPIKey, setBankVerificationAPIKey] = useState(sessionStorage.getItem("BankVerificationAPIKey") ? sessionStorage.getItem("BankVerificationAPIKey") : "");
    const [phoneConfiguration, setphoneConfiguration] = useState(); 
    const [phoneNumber, setPhoneNumber] = useState(""); 
    const [isPhoneNumberBlank, setIsPhoneNumberBlank] = useState(false); 
    const [isPhoneNumberInvalid, setIsPhoneNumberInvalid] = useState(false); 
    const [actionName, setActionName] = useState("");
    const [optionalEmail, setOptionalEmail] = useState(true);
    const discountFlow = sessionStorage.getItem("discountApplied");
   
   

    const phoneRef = useRef(null);
    let navigate=useNavigate();
   
    //***********************************basket methods begin***********************************/

    const updateAndRefreshBasket = (countryCode, type, result)=>{
  
       let basketData = result || basket   //either from the loadBasketCall or basket state
       
      //type can be "destinationcountrycode" or "origincountrycode" or "updateBasketForPromoCode"

        UpdateBasket({ "fid": fid, "setBasketErrors": setBasketErrors, "basket":(type==="destinationcountrycode") ? {...basketData, [type]: countryCode, "origincountrycode": countryCode} : (type==="origincountrycode") ? {...basketData, [type]: countryCode}: {...basketData}}).then(res=>{
          
           
            if(!res){
                if (isGuest) { 
                    loadGuestBasket(purchasingFor || "unknown", true, false);}
                 else { 
                    loadBasket(purchasingFor || "unknown", true, false);
                }
            }}).catch((error)=>{

                if (error.length && error.includes("error.basketcompletedorder")) {

                    // Handles the error related to a completed order

                    setBasketErrors(error);
                    setBasket({});
                } 
                else{
                    setBasketErrors(error);
                }
               })
             }


    const checkForRefreshADToken = async () => {

        let token = await RefreshADToken();
         
        if (token) {  
            //if token present session continues
            return;
        } else {

          //else redirection to landingPage  
          let url = '/checkout/' + fid + `?error=tokenExpired` ;
          navigate(url);
       }
    }

   useEffect(() => {

    if (isGuest) { 
        loadGuestBasket(purchasingFor || "unknown", false, true);}
    else { 
        loadBasket(purchasingFor || "unknown", false, true);}

        if(!isGuest){

            checkForRefreshADToken(); // first call on mount

            const interval = setInterval(() => {
                checkForRefreshADToken(); // checking every minute
            }, 60000);
     
            return () => clearInterval(interval); 

        }

    }, [])


    const loadGuestBasket = (purchaseTarget, forcedRefresh, resettingCountryOnMounting) => {
      
        if (!fid && basket?.id) {
            fid = basket.id;
        }

           
       if (!auth?.userData) {
            auth.ReloadUserData();
        }
    
      let guestToken = location?.state?.guestUser.token || auth?.userData?.token     

        
     GetBasket({ "fid": fid, "loadfromserver": true, "userToken": guestToken  , "purchaseTarget": purchaseTarget, }).then((result) => {

               //adding guest user party Id to the guest basket data,
               result = { ...result, partyid: location.state ? location.state.guestUser.partyid : 0}


                if(resettingCountryOnMounting) {
                    if(discountFlow){
                        let countryCode = sessionStorage.getItem("prevDeliveryCountryCode")
                        updateAndRefreshBasket(countryCode, "destinationcountrycode", result)    //updating destination country code in discounted basket with prev baskets for successful vat application
                    }else{
                    //setting the destination country to the initial country on refreshal
                    updateAndRefreshBasket(result.countrycode, "destinationcountrycode", result)
                    }
                }
                
                  //for vat in discountflow
               

                setBasket(result);  //guestBasket
            
                if (result?.paymentTypes?.length === 1) {
                    onChangePaymentSelection(result.paymentTypes[0]);
                }
                //first time or if the country changes and if not forced update as for that a call is already present

                if((!deliveryCountryCode || result.countrycode !== deliveryCountryCode) && !forcedRefresh){

                    if(discountFlow){
                        let countryCode = sessionStorage.getItem("prevDeliveryCountryCode")    //overriding discounted basket's country code with prev basket's
                        getAddressStructure(countryCode)  //to be run just once
                        setDeliveryCountryCode(countryCode)
                    } 
                    else{
                        getAddressStructure(result.countrycode)  //to be run just once
                        setDeliveryCountryCode(result.countrycode)
                    }
                 
                  
                }

                //in case we have an already logged in user going through guest flow 
                if(isGuest && !guestSession ){
                    let loggedInUser=GetADToken();

                    if(loggedInUser && !loggedInUser?.isGuest){
                        sessionStorage.setItem("loggedInUserEmail", loggedInUser?.username)
                    }

                }

                //In gift flows check will be at gift recipient level  
                if(guestSession && !result.allowgifting && !forcedRefresh){
                    let guestToken= GetADToken();
                    checkForBasketRestrictions(guestToken.partyid, guestToken.username, result);
                   
                }

         
               
                if (!forcedRefresh && !loqateKey && BankConfiguration===""){
                    getLoquateKey(result);
                 }

                setFlags({ ...flags, isdeliverycountryrequired: result.isdeliverycountryrequired, isdeliverypostaladdressrequired: result.isdeliverypostaladdressrequired, collectbillingaddress: result.collectbillingaddress })
                CheckIsContinuousMethodPresent(result);
                setVatDetails({"showTaxCode":result.canapplytaxcode, "taxCodeFormat":result.taxcodeformat})
                setShowCompany(!result.hidecompanyoncheckout);
                 
                const deliveryDetailsToShow = sessionStorage.getItem("deliveryDetails");  //to show saved deliveryDetails

                if(deliveryDetailsToShow && deliveryDetailsToShow !== "undefined"){
                    showSavedDeliveryDetails(deliveryDetailsToShow);
                }
              
                if(!discountFlow){
                    sessionStorage.setItem("originatingUrl", result.originatingurl)   //In discount flow overriding discounted basket's originatingUrl (incorrect) with original basket's
                }
              
                if (!fid) {
                    var newPath = window.location.protocol + "//" + window.location.host;
                    if (window.location.pathname) {
                        newPath += window.location.pathname;
                    }
                    newPath += result.id;
                    window.history.pushState({ path: newPath }, '', newPath);

                }
               
                // if (result.allowgifting && result.allowindividualcheckout){
                //     setPopUpFlow(true)
                //     if(!purchasingFor){
                //     setShowPopup(true);
                //     }
                // }
                // else{
                //     setPopUpFlow(false)
                // }  for later


        });
        
    }


    const loadBasket = (purchaseTarget,forcedRefresh, resettingCountryOnMounting) => {

     
      if (loading || forcedRefresh) {
            GetBasket({ "fid": fid, "loadfromserver": true, "userToken": auth?.userData?.token, "purchaseTarget":purchaseTarget }).then((result) => {

                if(resettingCountryOnMounting) {
                    if(discountFlow){
                        let countryCode = sessionStorage.getItem("prevDeliveryCountryCode")     //updating destination country code in discounted basket with prev baskets for successful vat application
                        updateAndRefreshBasket(countryCode, "destinationcountrycode", result)
                    }else{
                    //setting the destination country to the initial country on refreshal
                    updateAndRefreshBasket(result.countrycode, "destinationcountrycode", result)
                    }
                }

                setBasket(result);

                if (result?.paymentTypes?.length === 1) {
                    onChangePaymentSelection(result.paymentTypes[0]);
                }
               
                if((!deliveryCountryCode || result.countrycode !== deliveryCountryCode) && !forcedRefresh){

                    if(discountFlow){
                        let countryCode = sessionStorage.getItem("prevDeliveryCountryCode")
                        getAddressStructure(countryCode)  //to be run just once
                        setDeliveryCountryCode(countryCode)
                    } 
                    else{
                        getAddressStructure(result.countrycode)  //to be run just once
                        setDeliveryCountryCode(result.countrycode)
                    }
                 
                }

                if(!result.allowgifting && !forcedRefresh){
                    let userToken= GetADToken();
                    checkForBasketRestrictions(userToken?.partyid, userToken.username, result);  //stop multiple orders when enabled

                }

                
               if(!forcedRefresh && !loqateKey && BankConfiguration===""){
                    
                    getLoquateKey(result);
                }
                 
               setFlags({ ...flags, isdeliverycountryrequired: result.isdeliverycountryrequired, isdeliverypostaladdressrequired: result.isdeliverypostaladdressrequired, collectbillingaddress: result.collectbillingaddress })
               
                setVatDetails({ "showTaxCode": result.canapplytaxcode, "taxCodeFormat": result.taxcodeformat })

                const deliveryDetailsToShow = sessionStorage.getItem("deliveryDetails"); //to retain delivery details
                if(deliveryDetailsToShow && deliveryDetailsToShow !== "undefined"){
                    showSavedDeliveryDetails(deliveryDetailsToShow);
                }

                CheckIsContinuousMethodPresent(result);
                setShowCompany(!result.hidecompanyoncheckout);

                if(!discountFlow){
                sessionStorage.setItem("originatingUrl", result.originatingurl);
                }
            
               
            }).catch((errors) => {
                setBasketErrors(errors);
                setReturnUrl(errors.originatingurl)
                setLoading(false);
            });
        }
    }
    

    
     useEffect(()=>{
        if(!isGuest){
            setFid(fid); //to pass to metadata extraction
        }
    },[fid])

    //purchase restriction Stop Multiple Orders
    const checkForAlreadyPurchased=(partyid, username, csvForSMO, csvForPR, changeorderurl)=>{

       APIGet({
            "controller": "order",
            "action": "hasvariantorders",
            "identifier": partyid,
            "environment": environment,
            "data" : {"variantIdcsv": csvForSMO} 
       
        }).then((response) => {
            if(response.data.hasorders){
               let alreadyPurchasedText= format(t('error.alreadypurchased'), username, changeorderurl )
               setAlreadyPurchased({"status":"true", "text": alreadyPurchasedText}); // in case- any present variant already has been purchased
            }
            else{
               setAlreadyPurchased({"status":"false", "text": ""}); // going back to initial state
               checkForPurchaseRestriction(partyid , username , csvForPR , changeorderurl) //additional check on top of SMO
            }
         }).catch(function (errors) {
             console.log(errors);
         })
    }
    
    //Purchase restriction BI filter
    const checkForPurchaseRestriction=(partyid, username, csvForPR, changeorderurl)=>{

       APIGet({
            "controller": "order",
            "action": "haspurchaserestriction",
            "identifier": partyid,
            "environment": environment,
            "data" : {"variantIdcsv": csvForPR} 
       
        }).then((response) => {
            if(response.data.restrictpurchase){
               let purchaseRestrictedText= format(t('error.alreadypurchased'), username, changeorderurl )
               setPurchaseRestriction({"status":"true", "text": purchaseRestrictedText}); // in case- any present variant already has been purchased
            }
            else{

               setPurchaseRestriction({"status":"false", "text": ""}); // going back to initial state
            }
          }).catch(function (errors) {
             console.log(errors);
          })
    }

    //for basket checks-- keep adding the checks here    
    const checkForBasketRestrictions=(partyId, userName, basketData)=>{

        let csvForSMO= getCSV(basketData, "SMO");
        let csvForPR= getCSV(basketData, "PR");
        let changeorderurl="";
        
        if(!discountFlow){
            changeorderurl= basketData.originatingurl || sessionStorage.getItem("originatingUrl");
        }
        else{
            changeorderurl = sessionStorage.getItem("originatingUrl");
        }

        if(csvForSMO){
             
           setAlreadyPurchased({ "status": "false", "text": "" }); // we have variants with SMO enabled so now changing status to false(assuming)
           checkForAlreadyPurchased(partyId, userName, csvForSMO, csvForPR, changeorderurl)
           
        }
        else{

          if(csvForPR){

           setPurchaseRestriction({ "status": "false", "text": "" }); // we have variants with Restrict Purchasing enabled so now changing status to false(assuming)
           checkForPurchaseRestriction(partyId, userName, csvForPR, changeorderurl)
           
        }
      }
    }
   
   //csv values
   const getCSV=(result, type)=>{
  
      let variantIdArraySMO=[];  //stop multiple order flag enabled
      let variantIdArrayPR=[];   //purchase restriction BI filter enabled

      if(result?.lines){
         result.lines.forEach((variant, id)=>{
          
            variantIdArrayPR.push(variant?.productvariantid)
            if (variant?.stopmultipleorders===true){
                variantIdArraySMO.push(variant?.productvariantid)
               }
            })
        }

        let idForPromoCode = variantIdArrayPR.join(",")
        setVariantIdForPromoCode(idForPromoCode) //single variant id for promo code

        if(type==="SMO"){
           let csvForSMO = variantIdArraySMO.join(",");
           setVariantIdCsvSMO(csvForSMO);
           return csvForSMO;
        }

        if(type==="PR"){
           let csvForPR = variantIdArrayPR.join(",");
           setVariantIdCsvPR(csvForPR);
           return csvForPR;
        }
    }

       
  
    //Fetching client key for loquate

    const getLoquateKey=()=>{
         APIGet({
            "controller": "configuration",
            "action": "frontendconfiguration",
            "environment": environment,
         }).then((response) => {
             let config= response.data.configuration
             sessionStorage.setItem("loqateKey", config.loqateAPIKey );
             setLoqateKey(config.loqateAPIKey);
             sessionStorage.setItem("BankConfiguration", config.enableLoqateBankVerification); 
             setBankConfiguration(config.enableLoqateBankVerification);
             sessionStorage.setItem("BankVerificationAPIKey", config.loqateBankVerificationAPIKey);
             setBankVerificationAPIKey(config.loqateBankVerificationAPIKey)
    
          
          }).catch((errors)=>{})
    }

    
    //Fetching address structure using delivery country code: from basket

    const getAddressStructure = async (value) => {
      
        return APIGet({
            "controller": "country",
            "action": "getaddressstructure",
            "environment": environment,
            "identifier": value?.toLowerCase()
          }).then((response) => {
           
            let addressfields = response.data.addressfields;
            let companyField = addressfields.find((item, i) => {
                if (item.fieldName === "COMPANY") {
                  return item
                }
              });
            
              setCompanyProperties(companyField)
              setDeliveryAddressStructure(addressfields);
              setLoading(false);

          }).catch((errors) => {
        
            setLoading(false);
          });
        }

    //when we go back to delivery details block on clicking edit button
    useEffect(()=>{
          if(!sectionComplete.deliveryDetails){
           setBillingAddress({});
           setBillingCompany({});
           setErrorStates(errorStates=>({...errorStates, noPaymentSelected:false, autoRenewalNotTicked:false }))
          }
        }, [sectionComplete.deliveryDetails])


    useEffect(() => {
        if (basket?.lines) {
            basket.lines.forEach((variant, id) => {
                setphoneConfiguration(variant?.requirephone);
            })
        }
    }, [basket])



    useEffect(() => {
        //for guest flow 
        //as soon as guest session starts
        if (guestSession) {
            
            //validating refresh token for guest
            checkForRefreshADToken(); // first call on mount
    
            const interval = setInterval(() => {
            checkForRefreshADToken(); // checking every minute
            }, 60000);

            if(fid){
                setFid(fid); 
            }

            return () => clearInterval(interval); //clearing the interval
        }

     
     }, [isGuest])    
    

    useEffect(() => {
       
        let beginEventTriggered = sessionStorage.getItem("BeginEventTriggered");

        //only to fire if not already fired before on landingPage 
        if (metaData?.basketmetadata && beginEventTriggered === null) {
            gtmEventTriggers("begin_checkout");
            sessionStorage.setItem("BeginEventTriggered", true)
        }

        let adtoken = GetADToken();
        let loggedInUserEmail = sessionStorage.getItem("loggedInUserEmail")  //in case we have an already logged in user going through guest flow
        //web activity: for LOGGED IN user flows, if already not fired on landingPage then will fire here, to be fired once
        let checkoutStarted = sessionStorage.getItem("CheckoutStarted");
        let alreadyFiredFor = sessionStorage.getItem("CheckoutStartedFiredFor");
         
        //to be modified later *******
        
         if((!isGuest && !guestSession && (checkoutStarted === null)) || 
            (isGuest && guestSession && (checkoutStarted === "true") &&  (loggedInUserEmail && (adtoken.username !== loggedInUserEmail))) || 
            (!loggedInUserEmail && isGuest && guestSession && (checkoutStarted === null)) || 
            (isGuest && guestSession && (checkoutStarted === null) && (alreadyFiredFor===null) && loggedInUserEmail)||
            (isGuest && guestSession && (checkoutStarted === null) && (loggedInUserEmail && (adtoken.username !== loggedInUserEmail)))||
            (adToken && adToken?.isGuest && guestSession && discountFlow && (checkoutStarted===null))){

        //token is needed
               if (adtoken) {
                   setActionName("Checkout started")
                  
               }
         
        }

    }, [metaData?.basketmetadata])


    useEffect(() => {
       
        //min-shipping
        if (!isGuest && basket?.id && metaData?.basketmetadata && basket?.isdeliverypostaladdressrequired === false && basket?.allowgifting === false) {
            gtmEventTriggers("add_shipping_info"); 
            
        //min-billing  
        if (basket.collectbillingaddress === false) {
                gtmEventTriggers("add_billing_info");
            }
        }


        if (isGuest && guestSession && basket?.id && metaData?.basketmetadata && basket?.isdeliverypostaladdressrequired === false && basket?.allowgifting === false) {
            gtmEventTriggers("add_shipping_info");
 
            //when min-billing
            if (flags.collectbillingaddress === false) {
                gtmEventTriggers("add_billing_info");
            }
        }

    }, [basket?.id, metaData?.basketmetadata])    


    //auto-renewal checkbox depends on presence of CCC
    const CheckIsContinuousMethodPresent=(res)=>{
    let basket= res;
    let present= basket.paymentTypes.find((type, index)=>{
        return  (type.paymenttypeid===3 || type.paymenttypeid===7)
           })
    if(present){
        setIsContinuousMethodPresent(true)
       }
    }

   const handleAuthentication = (value) => {

        // Updates the state or perform other actions in the parent component

        setOAuthentication(value);
    };
    

    const onChangePaymentSelection = (value) => {
    
     
        let paymentTypeName = value?.name;
        setErrorStates(errorStates=>({...errorStates, noPaymentSelected:false}));
        setPaymentMethodErrors("");

        if (paymentTypeName === "Paypal")
       { 
            setIsPaypal(true);
            setIsDirectDebit(false);
            setPaymentSelection({ paymenttype : value, updatepaymentrequestfunction: UpdatePaypalPaymentRequest });
        }
        else if (paymentTypeName === "DirectDebit") {
           
            setIsDirectDebit(true);
            setPaymentSelection({ paymenttype : value, updatepaymentrequestfunction: UpdateDirectDebitPaymentRequest });
        }
        else if (paymentTypeName === "Invoicecredit" || paymentTypeName === "Invoiceproforma") {
            setIsDirectDebit(false);
            setPaymentSelection({ paymenttype: value, updatepaymentrequestfunction: UpdateInvoicePaymentRequest });
        }
        else if (paymentTypeName === "Creditdebitcard" || paymentTypeName === "Continuouscreditdebitcard") {
            
            setIsDirectDebit(false);
            
            if (basket.defaultpaymentprovider === "stripe") 
            {   
                setPaymentSelection({ paymenttype: value, updatepaymentrequestfunction: UpdateStripePaymentRequest });
                let userToken = GetADToken();
                if (userToken?.partyid > 0) {
                    handleInitializePayment(value);
                }
            } 
            if (basket.defaultpaymentprovider === "worldpay") {
                
                setPaymentSelection({ paymenttype: value, updatepaymentrequestfunction: null });
            }
        }
    }

    const onVatNoChanged = (value) => {
        setVatNo(value);
    }

    const paymentErrorHandler = (error) => {
        var paymentError = [];
        paymentError.push(error);
        setErrors(paymentError);
    }
 
    //method for focusing on first erring field

    const focusOnFirstError = () => {
        setTimeout(() => {
            let firstElement = paymentErrorFocusRef.current?.querySelectorAll("input[invalidclass='Invalid-input']")[0]
            firstElement?.focus();
        }, 200)
      
    }
     
     //when we go back to delivery details block on clicking edit button

     useEffect(()=>{
        if(!sectionComplete.deliveryDetails){
         setBillingAddress({});
         setBillingCompany({});
         setErrorStates(errorStates=>({...errorStates, noPaymentSelected:false, autoRenewalNotTicked:false }))
        }
      }, [sectionComplete.deliveryDetails])


   //************************************Validating methods begin *****************************//  
    const validateBilling = () => {

        let detailsFilled = true;
        let newIsFilled = { ...IsFilledBilling };
     

        if (flags.collectbillingaddress) {
            if (billingAddress.ADDRESS1 === undefined) {
                newIsFilled = { ...newIsFilled, ADDRESS: { filled: false, mandatory: true } };
                detailsFilled = false
            }
        }
        if (!billingAddress.COUNTRY) {
            newIsFilled = { ...newIsFilled, COUNTRY: { filled: false, mandatory: true } }
            detailsFilled = false
        }

        if(basket.offertypename==="corporate subscription"){
          if(!billingCompany || billingCompany.COMPANY.length<0){
            newIsFilled = { ...newIsFilled, ADDRESS: { filled: false, mandatory: true } };
            detailsFilled = false
          }
        }

        for (let key in IsFilledBilling) {
            if (key !== "ADDRESS" && key !== "COUNTRY" && key !== "RECIPIENT" && key !== "COMPANY") {
                if ( IsFilledBilling[key]?.mandatory && (billingAddress[key] === '' || !billingAddress[key])) {
                    newIsFilled = { ...newIsFilled, [key]: { filled: false, mandatory: true } }
                    detailsFilled = false
                }
            }
        }
       
      
        setIsFilledBilling({ ...newIsFilled })
        setSectionComplete(sectionComplete => ({ ...sectionComplete, billingDetails: detailsFilled }))

        if(!detailsFilled){
            focusOnFirstError();
        }
        else{
           
            if(flags.collectbillingaddress || flags.isdeliverypostaladdressrequired){
               gtmEventTriggers("add_billing_info");  //when billing address is required
            }
           
        }
        
        return detailsFilled;
    }


    const validateDelivery = () => {
       
        let detailsFilled = true;
        let newIsFilled = { ...IsFilledDelivery };
        if (flags.isdeliverypostaladdressrequired) {
            if (deliveryAddress.ADDRESS1 === undefined) {
                newIsFilled = { ...newIsFilled, ADDRESS: { filled: false, mandatory: true } };
                detailsFilled = false
            }
        }
        if (!deliveryAddress.COUNTRY) {
            newIsFilled = { ...newIsFilled, COUNTRY: { filled: false, mandatory: true } }
            detailsFilled = false
        }
        if ((purchasingFor === "gift" && basket.allowgifting) || (!purchasingFor && basket.allowgifting)) {
            if (!optionalEmail) {
                for (let key in recipientDetails) {
                    if (recipientDetails[key] === '') {
                        newIsFilled = { ...newIsFilled, RECIPIENT: { filled: "not_valid", mandatory: true } }
                        detailsFilled = false;
                    }
                }
            }
            else {
                for (let key in recipientDetails) {
                    if (recipientDetails[key] === '' && key !== "userEmail") {
                        newIsFilled = { ...newIsFilled, RECIPIENT: { filled: "not_valid", mandatory: true } }
                        detailsFilled = false;
                    }
                }
            }
        }
      
        if(flags.isdeliverypostaladdressrequired){
        for (let key in IsFilledDelivery) {
            if (key !== "ADDRESS" && key !== "RECIPIENT" ) {
                if ((!deliveryAddress[key] || deliveryAddress[key] === '') && IsFilledDelivery[key]?.mandatory) {
                    newIsFilled = { ...newIsFilled, [key]: { filled: false, mandatory: true } }
                    detailsFilled = false
                }
            }
        }}
        

        setIsFilledDelivery({ ...newIsFilled })
        setSectionComplete(sectionComplete => ({ ...sectionComplete, deliveryDetails: detailsFilled }))
        if(!detailsFilled){
            focusOnFirstError();
        } 
      
        return detailsFilled;

    }


    const validatePaymentSelection = ()=>{
        if(!paymentSelection.paymenttype ){
            setErrorStates(errorStates=>({...errorStates, noPaymentSelected:true}));
        return false;
        }
        else{
           setErrorStates(errorStates=>({...errorStates, noPaymentSelected:false}));
           return true;
        }
    }

    const validateDpaSelection = ()=>{
        if(!IsAutoRenewalChecked && IsContinuousMethodPresent){
            setErrorStates(errorStates=>({...errorStates, autoRenewalNotTicked:true}));
            return false;
        }
        else{
           setErrorStates(errorStates=>({...errorStates, autoRenewalNotTicked:false}));
           return true;
        }
    }


    const validateCreditCardHolderName =()=>{
        if(creditCardHolderName.length<=0){
            setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: true }));
            return false;
        }
        else{
            setErrorStates(errorStates => ({ ...errorStates, cardHolderNameBlank: false }));
            return true;
        }
    }


    /******************************************************Direct debit functions******************************************** */

    const validateDDAuth = () => {
        if (isDirectDebit) {
            if (!IsAuthDebitCardChecked) {
                setErrorStates(errorStates => ({ ...errorStates, authDD: true }));
                setShowError(true);
                return false;
            }
            else {
                setErrorStates(errorStates => ({ ...errorStates, authDD: false }));
                return true;
            }
        }
    }
    
    const validateDDName = () => {
        if (isDirectDebit) { 
        if (ddDetails.accountholder.length <= 0) {
          
            setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNameDD: true }));
            return false;
        }
        else {
            setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNameDD: false }));
            return true;
            }
        }
    }

    const validateDDBank = () => {
        if (isDirectDebit) {
            if (ddDetails.bankname.length <= 0) {
                setErrorStates(errorStates => ({ ...errorStates, isBlankBankDD: true }));
                return false;
            }
            else {
                setErrorStates(errorStates => ({ ...errorStates, isBlankBankDD: false }));
                return true;
            }
        }
    }

    const validateDDAccountNumber= () => {
          
        let valid =false;
       
            if (ddDetails.accountnumber.length <= 0) {
                setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNumberDD: true })); 
                valid = false;
            }
            else {
                 setErrorStates(errorStates => ({ ...errorStates, isBlankAccountNumberDD: false })); 
                 if ((ddDetails.accountnumber?.match(accountNumberRegex) || []).length <= 0) {
                    setErrorStates(errorStates=>({...errorStates, invalidFormatAccountNumberDD:true}));
                    valid = false;
                 }
                else {
                    setErrorStates(errorStates=>({...errorStates, invalidFormatAccountNumberDD:false}));
                    valid = true;
                }
            }
            return valid;
       }

    const validateDDSortCode= () => {
        let valid = false;
   
            if (ddDetails.sortcode.length <= 0) {
                setErrorStates(errorStates => ({ ...errorStates, isBlankSortCodeDD: true }));
                valid=false;
            }
            else {
                setErrorStates(errorStates => ({ ...errorStates, isBlankSortCodeDD: false }));
                if ((ddDetails.sortcode?.match(sortCodeRegex) || []).length <= 0) {
                    setErrorStates(errorStates=>({...errorStates, invalidFormatSortCodeDD:true}));
                    valid = false;
                 }
                else {
                    setErrorStates(errorStates=>({...errorStates, invalidFormatSortCodeDD:false}));
                    valid = true;
                }
              }
          return valid;
     }



    /*---------------------------------------------Loqate Bank Details Verification---------------------------------------------*/

    const validateLoqateBankVerification = () => {
        return new Promise((resolve, reject) => { 
            let accountNoValid = ddDetails.accountnumber && !errorStates.invalidFormatAccountNumberDD;
            let sortCodeValid = ddDetails.sortcode && !errorStates.invalidFormatSortcodeDD;
            let accountNo=ddDetails.accountnumber;
            let sortCode=ddDetails.sortcode;
            let accountName=ddDetails.accountholder
            let bankName=ddDetails.bankname;
           

            setErrorStates(errorStates=>({...errorStates, invalidAccountNumberDD:false}));
            setErrorStates(errorStates=>({...errorStates, invalidSortCodeDD:false}));
            
            //All fields if present then only the call should be allowed to go
    
            if (BankConfiguration && accountNoValid && sortCodeValid && accountName && bankName) {
                const url = 'https://api.addressy.com/BankAccountValidation/Batch/Validate/v1.00/json3.ws';
                const params = new URLSearchParams();
                params.append("Key", BankVerificationAPIKey);
                params.append("AccountNumbers", encodeURIComponent(accountNo));
                params.append("SortCodes", encodeURIComponent(sortCode));
    
                fetch(url, { method: 'POST', headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, body: params })
                    .then(response => response.json())
                    .then(data => {
                       
                        if (data.Items.length === 1 && !data.Items[0].Error ) {
                        resolve(data.Items);
                        loqateRequestLogs("1", data.Items[0], 2 ) //loqate request log for bank validation

                   } })
                    .catch(error => {
                      reject(error);
                    });

            } else {
                 resolve([]);
            }
        });
    };


    async function loqateBankVerificationResponseHandler() {
        let verified = false;
        let bankname =  "";
        try {

            let BankValidation = await validateLoqateBankVerification();

            setBankValidation(BankValidation);

            if (BankValidation.length > 0) {
                const statusInformation = BankValidation[0]?.StatusInformation;
                bankname = BankValidation[0]?.Bank;
                  
                //if the verification call is successful, typed in bank name will be overridden by the bank name in reponse.

                if(bankname){
                  setddDetails(ddDetails=>({ ...ddDetails, bankname: bankname}))
                }
    
                if (statusInformation === undefined) {

                    verified = false;

                } else if (statusInformation === 'InvalidAccountNumber') {
                    setErrorStates(errorStates=>({...errorStates, invalidAccountNumberDD:true}));
                    setErrorStates(errorStates=>({...errorStates, invalidSortCodeDD:false}));
                    verified = false;

                } else if (statusInformation === 'UnknownSortCode') {
                    setErrorStates(errorStates=>({...errorStates, invalidSortCodeDD:true}));
                    setErrorStates(errorStates=>({...errorStates, invalidAccountNumberDD:false}));
                    verified = false;

                } else if (statusInformation === 'OK') {
                    setErrorStates(errorStates=>({...errorStates, invalidAccountNumberDD:false}));
                    setErrorStates(errorStates=>({...errorStates, invalidSortCodeDD:false}));
                  
                    verified = true;
                }
            } else {
                verified = false;
            }
    
            return {"status": verified, "bankname": bankname} ;

        } catch (error) {
            console.error('Error in loqateBankVerificationResult:', error);
            throw error; 
        }
    };

    
   //****************************************validating methods end *********************************//
   
  //***************************************final payment call***************************************//


    
    //common variables 
     let loqateBankName = "";
     let loqateBankVerificationStatus = "";
     let offertypename = basket?.offertypename;
   

    const finalValidation = async() => {
        let validated=false;
        
        //general validations
        let billing = validateBilling();
        let delivery = validateDelivery();
        let payment = (validatePaymentSelection() || basket.offertypename === "free trial");
        let autoRenewal = validateDpaSelection();
        let phoneValidate = (phoneConfiguration === "Required" || phoneConfiguration === "Optional") ? phoneRef?.current?.validatePhoneNumber("", "onSubmit") : true;

        //direct debit validation functions
        let authDD = validateDDAuth();
        let accountNoDDValid=validateDDAccountNumber();
        let accountNameDDValid=validateDDName();
        let bankDDValid=validateDDBank();
        let sortcodeDDValid=validateDDSortCode();
        let directDebit= (accountNoDDValid && accountNameDDValid && bankDDValid && sortcodeDDValid)

        //only to run when bankValidation is needed
        if (BankConfiguration) {
            let loqateBankVerificationResult = await loqateBankVerificationResponseHandler();
            loqateBankName = loqateBankVerificationResult?.bankname
            loqateBankVerificationStatus = loqateBankVerificationResult?.status
        }

        //final checks
        if ((isDirectDebit ? billing && delivery && payment && autoRenewal && authDD && directDebit && phoneValidate && (!BankConfiguration || loqateBankVerificationStatus)
              : offertypename === "free trial" ? delivery && autoRenewal && phoneValidate  //free trial 
              : billing && delivery && payment && autoRenewal && phoneValidate)) {

            validated= true;
        }
        else {

            focusOnFirstError();
            validated= false;
        }

        return validated;

    }
    
   const onSubmitPayment = async(event) => {
    
        event.preventDefault();
        setErrorStates(errorStates=>({...errorStates, cardDeclined:false }))

       
        //worldplay flow change
        let isUsingWorldPay = (basket.defaultpaymentprovider === "worldpay" && paymentSelection?.paymenttype?.name === "Creditdebitcard")  

        let fieldsValidated= await finalValidation();

       if(fieldsValidated){
         

           setIsLoading(true);
           let data = {};
            //in case of bank validation the bank name will be updated from the loqate response
           let AccountDetails= (loqateBankName) ? {...ddDetails, bankname: loqateBankName} : ddDetails

            if(guestSession){

                let guestUserDetails= location.state ? location.state.guestUser : ''

                let guestTempName = guestUserDetails.name.trim(); //in case of spaces
                let purchaserName = guestTempName?.split(/\s+/);   
                let guestPurchaserFirstName = purchaserName[0];
                let guestPurchaserLastName = purchaserName[1];

                data = {
                    "purchaser": {
                        "Token": guestUserDetails?.token,
                        "email": guestUserDetails?.username,
                        "Surname": guestPurchaserLastName,
                        "Firstname": guestPurchaserFirstName,
                        "telephonenumber": phoneNumber,
                        "company": billingCompany,
                        "address": billingAddress,
                        "dpaFlag":IsMarketingPreferencesChecked
                       
                    },
                    "recipient": (purchasingFor==="gift" && basket.allowgifting)  || (!purchasingFor && basket.allowgifting) ? {
                        "email":  recipientDetails.userEmail ,
                        "Surname": recipientDetails?.lastName,
                        "FirstName": recipientDetails?.firstName,
                        "Token" : giftRecipientDetails.token,
                        "PartyId" : giftRecipientDetails.partyid,
                        "company": deliveryCompany,
                        "address": deliveryAddress
                    } : {
                        "telephonenumber": phoneNumber,   //when no recipient
                        "Surname": guestPurchaserLastName,
                        "Firstname": guestPurchaserFirstName,
                        "email":   guestUserDetails?.username,                        
                        "company": deliveryCompany,
                        "address": deliveryAddress
                    },
                    "payment": {
                        "PaymentType": paymentSelection?.paymenttype,
                        "AccountDetail": AccountDetails,
                        "ContinuousPaymentAuthorised": IsAutoRenewalChecked
                    }
                };
            } else{
                
                let loggedInUserTempName = auth?.userData?.name.trim();
                let purchaserName = loggedInUserTempName?.split(/\s+/);
                let purchaserFirstName = purchaserName[0];
                let purchaserLastName = purchaserName[1];

             data = {
                "purchaser": {
                    "Token": auth?.userData?.token,
                    "email": auth?.userData?.username,
                    "Firstname":purchaserFirstName,
                    "Surname":purchaserLastName,
                    "company": (offertypename==="free trial") ? deliveryCompany : billingCompany ,
                    "address": (offertypename==="free trial") ? deliveryAddress : billingAddress,
                    "telephonenumber": phoneNumber,
                 
                },
                "recipient":(purchasingFor==="gift" && basket.allowgifting)  || (!purchasingFor && basket.allowgifting) ?  {
                        "email":  recipientDetails.userEmail ,
                        "Surname": recipientDetails?.lastName,
                        "FirstName": recipientDetails?.firstName,
                        "Token" : giftRecipientDetails.token,
                        "PartyId" : giftRecipientDetails.partyid,
                        "company": deliveryCompany,
                        "address": deliveryAddress
                } :{
                    "telephonenumber": phoneNumber,    //when no recipient
                    "Firstname":purchaserFirstName,
                    "Surname":purchaserLastName,
                    "email":  auth?.userData?.username,
                    "company": deliveryCompany,
                    "address": deliveryAddress
                },
                "payment": {
                    "PaymentType":  paymentSelection?.paymenttype,
                    "AccountDetail": AccountDetails,
                    "ContinuousPaymentAuthorised" : IsAutoRenewalChecked
                }
            };
        }
        
          let paymentTypeName = paymentSelection?.paymenttype?.name;

          if ((paymentTypeName === "Creditdebitcard" || paymentTypeName === "Continuouscreditdebitcard") && basket.defaultpaymentprovider === "stripe")
          {   
            Promise.all([validateCreditCardHolderName(), paymentConfirmRef.current.elements.submit()])
            .then(([validationResult, submitResult]) => { 
               
                      if (validationResult && !submitResult.error) {
                        SaveToOrder({ fid: fid, data: data }).then((oresult) => {
                           
                           // add the orderId into the payment request
                           
                            if (oresult.ordertype === "free trial" || oresult.ordertype === "paid free trial") {
                                if (oresult.ordertype === "paid free trial") {
                                    paymentConfirmRef.current.confirmPayment(event, oresult.ordertype === "paid free trial");
                                }
                                else {
                                    APIPost({
                                        "controller": "freetrial",
                                        "action": "completefreetrialsetup",
                                        "data": {
                                            "fid": fid
                                        },
                                        "environment": null,
                                        "identifier": null
                                    }).then((ftresponse) => {
                                        window.location.href = window.location.protocol + "//" + window.location.host + "/thankyou/" + ftresponse.data.OrderId;
                                    }).catch((fterror) => {
                                        paymentErrorHandler(fterror);
                                        setIsLoading(false);
                                    });
                                }
                            }
                            else if (paymentSelection.updatepaymentrequestfunction) {
                                let stripeIntentId = "NA";
                                if (stripeCredentials)
                                    stripeIntentId = stripeCredentials.intentid;

                                paymentSelection.updatepaymentrequestfunction({ "basket": basket, "orderId": oresult.orderId, "stripeIntentId": stripeIntentId }).then((upresult) => {

                                    paymentConfirmRef.current.confirmPayment(event, basket.offertypename === "paid free trial");
                                }).catch((uperror) => {
                                    paymentErrorHandler(uperror);
                                    setIsLoading(false)
                                });
                            }
                            else {
                                paymentConfirmRef.current.confirmPayment(event, basket.offertypename === "paid free trial");
                            }
                        }).catch((error) => {
                            if (error.length && error.includes("error.basketcompletedorder")) {

                                // Handle the specific error related to a completed order

                                setBasketErrors(error)
                                setBasket({});
                            }
                            else {
                                paymentErrorHandler(error);
                            }
                            setIsLoading(false)
                        });
                }
                else {
                    setIsLoading(false)
                }
            })
             
          }
          else { 

              SaveToOrder({ fid: fid, data: data }).then((oresult) => {
                  if (oresult.ordertype === "free trial" || (oresult.ordertype === "paid free trial" && !isUsingWorldPay)) {
                      let action = "completefreetrialsetup";
                      if (oresult.ordertype === "paid free trial") {
                          action = "completetokenisedfreetrialsetup";
                      }
                      APIPost({
                          "controller": "freetrial",
                          "action": action,
                          "data": {
                              "fid": fid
                          },
                          "environment": null,
                          "identifier": null
                      }).then((ftresponse) => {
                          window.location.href = window.location.protocol + "//" + window.location.host + "/thankyou/" + oresult.orderId;
                      }).catch((fterror) => {
                          paymentErrorHandler(fterror);
                          setIsLoading(false);
                      })
                  }
                  else if (isUsingWorldPay) {
                      //once basket gets converted to order to show worldpay card details section
                      setShowWorldPay(true);
                      setIsLoading(false);

                  }
                  else {
                      if (paymentSelection.updatepaymentrequestfunction) {
                          // add the orderId into the payment request
                          paymentSelection.updatepaymentrequestfunction({ "basket": basket, "orderId": oresult.orderId }).then((upresult) => {
                              paymentConfirmRef.current.confirmPayment(event, basket.offertypename === "paid free trial");
                          }).catch((uperror) => {
                              paymentErrorHandler(uperror);
                              setIsLoading(false)
                          });
                      } else {
                          paymentConfirmRef.current.confirmPayment(event, basket.offertypename === "paid free trial");
                      }
                  }

            }).catch((error) => {

                  //basket error handling
                  
                  if (error.length && error.includes("error.basketcompletedorder")) {

                    // Handle the specific error related to a completed order

                   setBasketErrors(error)
                   setBasket({});
                } 
                else {
                    paymentErrorHandler(error);
                }
               setIsLoading(false)
            });}
      }
      else {

          focusOnFirstError();
          setIsLoading(false)
      }

        //window.location.href =  window.location.protocol + "//" + window.location.host + "/OrderComplete";
    }

    //******************************************final payment call ends********************************/

    let paymentButtonText = t('payment.subscribebutton');
    let continueToWorldpayButtonText="";

    if (paymentSelection === "Paypal") {
        paymentButtonText = t('payment.paywithpaypalbutton');

    } else if( paymentSelection?.paymenttype?.name === "Creditdebitcard" && basket.defaultpaymentprovider==="worldpay"){

         //worldpay continue button text

        continueToWorldpayButtonText = t('payment.paywithworldpaybutton');
    }

    let submitDisabled = false;

    const termsText = props.brandingElement?.termsText || '';
    
    //stripe initialising method

    const handleInitializePayment = (paymenttype)=>{
       
        InitialiseStripe({ "paymentSelection": paymenttype?.paymenttypeid, "basket": basket}).then((stripeResult) => {
        setStripeCredentials(stripeResult);
      
      }).catch((error) => {
       
        if(error.response.status===500){
            setPaymentMethodErrors("500_errors")
        }
      });
  }

 // to render card-Details Ui as per selection
    const displayForm = () => {
      
        const paymentTypeName = paymentSelection?.paymenttype?.name;
      
        if (paymentTypeName === "Paypal") {
            return <PaypalForm onAuthentication={handleAuthentication} billingCountryCode={billingCountryCode} billingAddress={billingAddress} basket={basket} paymentTypeId={paymentSelection.paymenttype.paymenttypeid} ref={paymentConfirmRef} onError={paymentErrorHandler} autoRenewal={IsAutoRenewalChecked} errorStates={errorStates} /> 
        }
        else if (paymentTypeName === "DirectDebit") {
            return BankConfiguration ? <DirectDebitForm basket={basket} setddDetails={setddDetails} ddDetails={ddDetails}  ref={paymentConfirmRef}  onError={paymentErrorHandler} ddTerms={props?.brandingElement?.ddTermsText} setIsAuthDebitCardChecked={setIsAuthDebitCardChecked} setErrorStates={setErrorStates} errorStates={errorStates} showError={showError} BankConfiguration={BankConfiguration} BankValidation={BankValidation}/> 
            : <DirectDebitForm basket={basket} setddDetails={setddDetails} ddDetails={ddDetails} onError={paymentErrorHandler} ref={paymentConfirmRef} ddTerms={props?.brandingElement?.ddTermsText} setIsAuthDebitCardChecked={setIsAuthDebitCardChecked} setErrorStates={setErrorStates} errorStates={errorStates} showError={showError}/>
        }
        else if (paymentTypeName === "Invoicecredit" || paymentTypeName === "Invoiceproforma") {
            return <Invoice basket={basket} ref={paymentConfirmRef} setErrorStates={setErrorStates} errorStates={errorStates} showError={showError} />
        }
        else if (paymentTypeName === "Creditdebitcard" || paymentTypeName === "Continuouscreditdebitcard") {
            if ( basket.defaultpaymentprovider === "stripe") {  
                if (stripeCredentials && paymentSelection?.updatepaymentrequestfunction ) {
                 return (<>
                        <div id="stripe-dropin-container">
                            <StripeForm errorStates={errorStates} setErrorStates={setErrorStates} creditCardHolderName={creditCardHolderName} setCreditCardHolderName={setCreditCardHolderName} billingCountryCode={billingCountryCode} isLoading={isLoading} setIsLoading={setIsLoading} stripeCredentials={stripeCredentials} billingAddress={billingAddress} basket={basket} paymentTypeId={paymentSelection.paymenttype.paymenttypeid} ref={paymentConfirmRef} onError={paymentErrorHandler} />
                        </div> 
                    </> )                  
                }
            }
            else if (basket.defaultpaymentprovider === "worldpay") {
                return (<>                   
                    <div id="wpcontainer"><WorldPayForm
                        billingCountryCode={billingCountryCode}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        wpHostedURL={wpHostedURL}
                        billingAddress={billingAddress}
                        onSubmitPayment={onSubmitPayment}
                        basket={basket}
                      
                        setPaymentMethodErrors={setPaymentMethodErrors}
                        paymentTypeId={paymentSelection.paymenttype.paymenttypeid}
                        ref={paymentConfirmRef}
                        onError={paymentErrorHandler} />
                    </div>
                </>)
            }}}
   
    //**********************************modal functions begin ***********************************//


    const openModal = () => {
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    }

    const handleResize = () => {

        // Update isOnModal based on the current state of the modal

        setIsMobile(windowSize < 1000 && showModal);
    };

    useEffect(() => {

        // Attach the resize event listener

        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [windowSize]);

    useEffect(() => {
        if (showModal) {

            // Disable scrolling on the entire page when the modal is open

            document.body.style.overflow = 'hidden';
            document.body.style.position = 'fixed';
        } else {

            // Re-enable scrolling when the modal is closed

            document.body.style.overflow = 'auto';
            document.body.style.position = 'relative';
        }

        // Cleanup style on component unmount
        return () => {
            document.body.style.overflow = 'auto';
            document.body.style.position = 'relative';

        };
    }, [showModal]);

    const basketSummaryForMobile=()=>{
        return  <div className="item-container">
        <div class="border-seperator"></div>
        <div class="item-wrapper">
            <div class="two-columns-row">
                <span>Sub-total</span>
                    <span class="order-price"> {basket?.currencysymbol}{basket?.subtotal?.toFixed(2)}</span>
            </div>
            <div class="two-columns-row">
                <span>Tax</span>
                    <span class="order-price">{basket?.currencysymbol}{basket?.tax?.toFixed(2)}</span>
            </div>
            {basket.delivery &&
                <div className="two-columns-row">
                    <span>Delivery</span>
                        <span class="order-price">{basket?.currencysymbol}{basket?.delivery?.toFixed(2)}</span>
                </div>
            }
            <div class="border-seperator"></div>
            <div class="two-columns-row">
                <span class="order-price">
                    <strong> Order total</strong>
                </span>
                    <span class="order-price">
                        <strong>{basket?.currencysymbol}{basket?.gross?.toFixed(2)}</strong>
                </span>
            </div>
        </div>
        <div class="border-seperator"></div>
    </div>
    }

    //****************************************modal functions end***********************//

    useEffect(()=>{

       //in case of only 1 payment method making it the default

        if(basket.paymentTypes?.length===1 && paymentSelection.paymenttype===null){
         onChangePaymentSelection(basket.paymentTypes[0])
        }
        
    }, [basket])

    const handleOnBackButton = () =>{

        //should take us to step 1 of world pay flow

        setShowWorldPay(false);
    }

    //showing delivery details on completion of the step
    const showSavedDeliveryDetails=(deliveryDetails)=>{
    
    let temp= JSON.parse(deliveryDetails);
    let recipient= temp.recipient;
    let company = temp.company;
    let address = temp.address

    if(recipient){
        setRecipientDetails(recipient)

    }
    if(company){
        setDeliveryCompany(company)
       
    }
    if(address){
        setDeliveryAddress(address)
       
    }
    setSectionComplete(sectionComplete => ({ ...sectionComplete, deliveryDetails: true }))

}
   return (<>{ !loading ? <>

                 {/* when basket call errs */}

                <div className="container-wrapper one-column">
                  <GenericErrorBox errors={basketErrors} url={getOriginatingUrl(basket, returnUrl)}  refreshUrl={window.location.href} startAgainUrl={getOriginatingUrl(basket, returnUrl)} thankyouPageUrl={getThankyouPageUrl()}/>
                </div>

       {/* when basket call goes successfully then only the checkout page will show */}    

        {basket?.id &&
         <div class="container-wrapper two-columns" ref={paymentErrorFocusRef}>
            <section>
                <div className="container block-title">
                    <div className="item-container">
                        <div className="item-wrapper">  
                            <h1 className="block-header"> {t('payment.blockTitle') } </h1>
                        </div>
                    </div>
                </div>
               
                    {windowSize < 1000 &&

                    //  For mobile view: the basket data is going to show up on a modal
                        <> 
                       {showModal && <Modal isOpen={showModal} onClose={closeModal} buttonStyle={'close'}>
                           <Basket basket={basket} deliveryCountry={deliveryAddress.COUNTRY} loading={loading} isOnModal={showModal} />
                            </Modal>}

                            <div class="container">
                                <div className="item-container">
                                    <div className="item-wrapper"> <div className="two-columns-row">
                                        <h2 className="module-heading">{t('basket.title')} <span className="meta-info">({basket.lines.reduce((acc, line) => acc + line.quantity, 0)} items)</span> </h2>
                                        <button onClick={openModal} className='text-button'>View</button>
                                    </div>
                                    </div>
                                </div>
                            </div>

                        </>
                    }

                <div className="container">
                    <div className="item-container">
                        <h2 className="module-heading">{t('checkout.usertitle')}</h2>
                    </div>

                    {/* User details block dependent on if it is guest flow or logged in user flow*/}

                    <div className="item-container">
                           <div className="item-wrapper">
                       {(isGuest || guestSession) ? 
                        props.reCaptchaKey && 
                        <GoogleReCaptchaProvider  reCaptchaKey={props.reCaptchaKey} scriptProps={{ async: true }}>
                        <GuestUser 
                        guestSession={guestSession}
                        basket={basket} 
                        adToken={adToken}
                        setAlreadyPurchased={setAlreadyPurchased}
                        originatingurl={getOriginatingUrl(basket, returnUrl)}
                        hasRecaptcha={props.reCaptchaKey!=="no_recaptcha"}
                        setGuestSession={setGuestSession}
                        sectionComplete={sectionComplete} 
                        setSectionComplete={setSectionComplete}
                        setPurchaseRestriction={setPurchaseRestriction}
                                       />
                                  
                      </GoogleReCaptchaProvider> : <> <LoginInfo />  </>}
                       </div>
                       </div>
        
                       {phoneConfiguration !== "Not shown" &&
                           <div className="item-container">
                               <div className="item-wrapper">
                                   <CapturePhone phoneConfiguration={phoneConfiguration}
                                   isPhoneNumberBlank={isPhoneNumberBlank } 
                                   setIsPhoneNumberBlank={setIsPhoneNumberBlank}
                                   isPhoneNumberInvalid={isPhoneNumberInvalid}
                                   setIsPhoneNumberInvalid={setIsPhoneNumberInvalid}
                                   phoneNumber={phoneNumber}
                                   setPhoneNumber={setPhoneNumber}
                                   ref={phoneRef}
                                />
                               </div>
                           </div>
                       }
                </div>
                
                    {/* Flow specific to worldpay-2nd step that has card details block */}

                    {showWorldPay ? <>

                        <div class={`container ${billingLoadingState ? `loading-state` : ""}`}>
                            <div className="two-columns-row">
                                 <div className="item-container">
                                     <h5 className="module-heading">{t('worldpayform.title')}</h5>
                                 </div>

                                 <a href="javascript:void(0)" onClick={handleOnBackButton} className="back-button flex-direction-row">
                                     <span className="back-icon"></span>
                                     <p>{t('worldpay.backlinktext')}</p>
                                </a>
                            </div>

                            {paymentSelection ? displayForm() : ""}
                            {paymentMethodErrors ? <GenericErrorBox errors={paymentMethodErrors} type="paymentMethodErrors" url={getOriginatingUrl(basket, returnUrl)} refreshUrl={window.location.href} /> : ""}

                        </div></> :

                    // Flow for other payment methods
              <> 
                <GenericErrorBox errors={errors} />

                <div class={`container ${billingLoadingState ? `loading-state` : ""}`}>
                {
                   ((alreadyPurchased.status==="true" || purchaseRestriction.status==="true") && !basket.allowgifting) ?  
                      <MessageBox messageState="error">{(alreadyPurchased?.text|| purchaseRestriction.text) && ReactHtmlParser(alreadyPurchased?.text||purchaseRestriction?.text||"")}</MessageBox>
                      : 
                      
                         (isGuest && !guestSession && purchaseRestriction.status==="") ?       
                         
                           <h2 className="module-heading disabled">{t('delivery.title')}</h2>

                           : 
                        
                       ((isGuest && guestSession) || (!isGuest)) ?  <>

                       {/* if guest and guest session has started-****-not guest then delivery block should show */}

                        <div className="item-container">
                            <h2 className="module-heading">{t('delivery.title')}</h2>
                        </div>
                        
                       <DeliveryDetails
                        IsFilled={IsFilledDelivery}
                        setIsFilled={setIsFilledDelivery}
                        setGiftRecipientDetails={setGiftRecipientDetails}
                        flags={flags}
                        showCompany={showCompany}
                        deliveryAddressStructure={deliveryAddressStructure}
                        setDeliveryAddressStructure={setDeliveryAddressStructure}
                        ref={deliveryRef}
                        fid={fid}
                        optionalEmail={optionalEmail}
                        setOptionalEmail={setOptionalEmail}
                        loqateKey={loqateKey}
                        guestSession={guestSession}
                        setAlreadyPurchased={setAlreadyPurchased}
                        setPurchaseRestriction={setPurchaseRestriction}
                        checkForBasketRestrictions={checkForBasketRestrictions}
                        recipientDetails={recipientDetails}
                        getAddressStructure={getAddressStructure}
                        setRecipientDetails={setRecipientDetails}
                        selectedAddress={deliveryAddress}
                        setSelectedAddress={setDeliveryAddress}
                        selectedCompany={deliveryCompany}
                        setSelectedCompany={setDeliveryCompany}
                        sectionComplete={sectionComplete}
                        setSectionComplete={setSectionComplete}
                        deliveryCountryCode={deliveryCountryCode}
                        setDeliveryCountryCode={setDeliveryCountryCode}
                        companyProperties={companyProperties}
                        setcompanyProperties={setCompanyProperties}
                        basket={basket}
                        companyList={companyList}
                        loading={loading}
                        setBillingLoadingState={setBillingLoadingState}
                        setLoading={setLoading}
                        onVatNoChanged={onVatNoChanged} 
                        updateAndRefreshBasket={updateAndRefreshBasket}/>
                     
                        </>
                        
                    :"" }
                </div>

                  {basket.allowgifting && (alreadyPurchased.status==="true" || purchaseRestriction.status==="true") && sectionComplete.deliveryDetails ? 
                     <div class="container" >
                        <MessageBox messageState="error">{(alreadyPurchased?.text && ReactHtmlParser(alreadyPurchased?.text || ""))||(purchaseRestriction?.text && ReactHtmlParser(purchaseRestriction?.text || ""))}</MessageBox>
                     </div>

                  :""}

                    <div class="container" >
                        {/*needs revisiting*/}
                        {(((isGuest && guestSession && flags.isdeliverycountryrequired && !flags.isdeliverypostaladdressrequired && !basket.allowgifting) ||
                          (!isGuest && flags.isdeliverycountryrequired && !flags.isdeliverypostaladdressrequired && !basket.allowgifting) ||

                          (isGuest && guestSession && purchasingFor === "self" && flags.isdeliverycountryrequired && !flags.isdeliverypostaladdressrequired) ||
                           sectionComplete.deliveryDetails || (guestSession && purchasingFor === "gift" && sectionComplete.deliveryDetails)) || deliveryDetails)  && (alreadyPurchased.status!=="true" && purchaseRestriction.status!=="true") ? <>

                            <div className="item-container">
                                <h2 className="module-heading">{t('payment.title')}</h2>
                            </div>
                           
                           {/* Promo Code */}
                           {basket?.promotionapplicationmode !== "none" ?
                            <div className="item-container">
                               <div className="item-wrapper">
                                 
                                  <PromoCode fid={fid} setActionName={setActionName}  adToken ={adToken} purchasingFor={purchasingFor} deliveryCountryCode={deliveryCountryCode} variantIdForPromoCode={variantIdForPromoCode} updateAndRefreshBasket={updateAndRefreshBasket} basket={basket} />
                               </div>
                            </div>     
                            :""}
                            
                            {basket.offertypename==="free trial" ? 
                            
                            <div className="item-container">
                            <MessageBox messageState="info">{t('freetrial.message')}</MessageBox>
                            </div> 

                            : <>
                            <div className="item-container">
                                <h5 className="module-subheading">{t('billingdetails.title')}</h5>
                            </div>
                          
                            <BillingDetails
                                    IsFilled={IsFilledBilling}
                                    setIsFilled={setIsFilledBilling}
                                    IsFilledDelivery={IsFilledDelivery}
                                    ref={billingRef}
                                    fid={fid}
                                    basket={basket}
                                    setBasket={setBasket}
                                    vatDetails={vatDetails}
                                    deliveryAddressStructure={deliveryAddressStructure}
                                    loqateKey={loqateKey}
                                    showCompany={showCompany}
                                    sectionComplete={sectionComplete}
                                    setSectionComplete={setSectionComplete}
                                    selectedAddress={billingAddress}
                                    setSelectedAddress={setBillingAddress}
                                    deliveryAddress={deliveryAddress}
                                    setBasketErrors={setBasketErrors}
                                    setBillingCountryCode={setBillingCountryCode}
                                    setBillingsSameCheck={setBillingsSameCheck}
                                    purchasingFor={purchasingFor}
                                    deliveryCompany={deliveryCompany}
                                    selectedCompany={billingCompany}
                                    setSelectedCompany={setBillingCompany}
                                    deliveryCountryCode={deliveryCountryCode}
                                    companyProperties={companyProperties}
                                    setCompanyProperties={setCompanyProperties}
                                    flags={flags}
                                    companyList={companyList}
                                    updateAndRefreshBasket={updateAndRefreshBasket}
                                    loading={loading} />

                                    </>

                               }

                          <form method="Post" onSubmit={onSubmitPayment}>

                          {basket.offertypename==="free trial" ? "" :
                               <div className="item-container">
                                   <div className="border-seperator"></div>
                               </div>
                           }
                                    {/* Payment selection should only show up when there are more than 1 type */}

                                    {basket?.paymentTypes?.length > 1 ?

                                        <div className="item-container">
                                            <PaymentSelection paymentTypes={basket?.paymentTypes} setErrorStates={setErrorStates} loading={loading} onChangePaymentSelection={onChangePaymentSelection} />
                                            
                                            {errorStates.noPaymentSelected &&
                                                <span role="alert" id='payment-not-selected'><ErrorBox>{t('paymenttype.methodnotselected')}</ErrorBox></span>
                                            }

                                        </div> : ""}
                                     
                                     {/* Incase of worldpay nothing will show in Card details UI as that'll be done in the next step--**--when chosen anything else the normal flow will continue */}

                                     {(basket.defaultpaymentprovider === "worldpay" && paymentSelection?.paymenttype?.name === "Creditdebitcard") ? "" :
                                       <>
                                            {paymentSelection ? displayForm() : ""}
                                            {paymentMethodErrors ? <GenericErrorBox errors={paymentMethodErrors} type="paymentMethodErrors" url={getOriginatingUrl(basket, returnUrl)} refreshUrl={window.location.href} /> : ""}</>

                                        }

                                   {/* Only to show when Continuous payment method is present */}

                                   {IsContinuousMethodPresent ? <AutorenewalTerms setIsAutoRenewalChecked={setIsAutoRenewalChecked} setErrorStates={setErrorStates} errorStates={errorStates} renewalText={props.brandingElement?.renewalTerms} /> : ""}
                                   
                                   {/* Only to show when guest */}

                                   {isGuest ? <MarketingPreferences branding={props.branding} originatingurl={getOriginatingUrl(basket, returnUrl)} setIsMarketingPreferencesChecked={setIsMarketingPreferencesChecked} setErrorStates={setErrorStates} errorStates={errorStates} />  : ""}
                                       <div className="item-container">
                                          <div className="meta">{ReactHtmlParser(termsText)} </div>
                                       </div>
                                 
                                   {windowSize < 1000 && basketSummaryForMobile()}
                            

                            {/* button snippet */}
                                     
                                    {paymentSelection.paymenttype?.name === "Paypal" ?

                                    //button element specific to paypal

                                        (!isLoading && oAuthentication ?
                                            <Button type="submit" buttonSize="small">{paymentButtonText}</Button>
                                            : ((isLoading) ?
                                                <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{paymentButtonText}</Button>
                                                : <Button type="submit" buttonSize="small" isDisabled={`${isPaypal ? 'true' : 'false'}`} > {paymentButtonText}</Button>)
                                        )
                                        : (basket.defaultpaymentprovider === "worldpay" && paymentSelection?.paymenttype?.name === "Creditdebitcard") ?

                                            //button element specific to worldpay

                                            (!isLoading ? <Button type="submit" buttonSize="small">{continueToWorldpayButtonText}</Button>
                                                : <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{continueToWorldpayButtonText}</Button>)

                                            :  //button element for all other payment methods

                                            (!isLoading ? <Button type="submit" buttonSize="small">{paymentButtonText}</Button>
                                                : isLoading ? <Button type="submit" hasIcon="updating" isDisabled="true" buttonSize="small">{paymentButtonText}</Button>
                                                    : "")
                                }
                                 
                          </form>
                          
                          
                    </> : <h2 className="module-heading disabled">{t('payment.title')}</h2>}
                </div>
                </>
                }
            </section>
         
         {windowSize > 1000 &&
                <section>
                    <div class="container summary">
                        <Basket basket={basket} deliveryCountry={deliveryAddress.COUNTRY} loading={loading} />
                    </div>
                </section>
            }

            
            <LogWebActivity actionName={actionName} isGuest={isGuest} guestSession={guestSession}/>
        </div>
        }
    </>
    : <Loading loading={loading} />}
        </>
        
    )

}